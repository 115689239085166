<template>
  <div class="inform">
    <div class="inform_top">
      <div class="left">
        <img :src="cancel" @click="toAddUserInfoFn" alt />
      </div>
      <div class="center">上传人脸照片</div>
    </div>
    <div class="face">
      <img :src="addUserData.picture?`${apiURL}/image?name=${addUserData.picture}`: face" alt />
    </div>
    <div class="tips">
      <div class="title">上传人脸照片说明</div>
      <ul>
        <li>1、近期人脸正面免冠照，露出眉毛和眼睛。</li>
        <li>2、照片最好选择白底或者蓝底、无逆光、无PS、无过度美颜处理。</li>
        <li>3、人脸占整个画面的50%以上。</li>
        <li>4、不使用全身照、风景为背景等照片。</li>
      </ul>
    </div>
    <div class="upload">
      <div v-if="!isAndroid" class="items">
        <div class="item-flex" @click="handler">
          <div>上传人脸</div>
        </div>
        <div v-show="false">
          <van-uploader
            ref="uploader"
            v-model="fileList"
            :after-read="(file) => onLoadCard(file, 'front')"
            :max-count="1"
            :max-size="50 * 1024 * 1024"
            :preview-image="false"
          />
        </div>
      </div>
      <div v-if="isAndroid" class="items">
        <div v-if="isAndroid" class="items">
          <div class="item-flex" @click="handlerAlbum">
            <div>从相册选一张</div>
          </div>
          <div v-show="false">
            <van-uploader
              ref="uploaderAlbum"
              v-model="fileList"
              accept="album/*"
              :after-read="(file) => onLoadCard(file, 'front')"
              :max-count="1"
              :max-size="50 * 1024 * 1024"
              :preview-image="false"
            />
          </div>
        </div>
        <div class="item-flex" @click="handlerImage">
          <div>拍一张照</div>
        </div>
        <div v-show="false">
          <van-uploader
            ref="uploaderImage"
            v-model="fileList"
            :after-read="(file) => onLoadCard(file, 'front')"
            :max-count="1"
            :max-size="50 * 1024 * 1024"
            :preview-image="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import right from "@/assets/right.png";
import face from "@/assets/人脸.jpg";
import { Toast, Dialog } from "vant";
import {
  getTransferListAPI,
  noCheckAvatarAPI,
  addTransferAPI,
} from "@/api/pick-up";

export default {
  data() {
    return {
      cancel,
      right,
      face,
      fileList: [],
      data: {
        userId: "",
        manager: "",
      },
      personInfo: {
        id: null,
        classManager: null,
        departmentManager: null,
      },
      addUserData: {},
      apiURL: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    this.addUserData = JSON.parse(this.$route.query.item);
    this.personInfo = JSON.parse(sessionStorage.getItem("personInfo"));
    this.data = {
      userId: this.personInfo.id,
      manager: this.personInfo.classManager
        ? this.personInfo.classManager.id
        : "" || this.personInfo.departmentManager
        ? this.personInfo.departmentManager.id
        : "",
    };
    console.log(this.addUserData);
  },
  computed: {
    isAndroid() {
      return navigator.userAgent.indexOf("Android") > -1;
    },
  },
  methods: {
    async onLoadCard(file) {
      noCheckAvatarAPI({
        fileName: file.file.name,
        userId: this.personInfo.id,
        manager: this.personInfo.classManager
          ? this.personInfo.classManager.id
          : "" || this.personInfo.departmentManager
          ? this.personInfo.departmentManager.id
          : "",
        image: file.content.split(",")[1],
      })
        .then((res) => {
          if (res.code === 0) {
            this.addUserData.picture = res.data.name;
            console.log(this.addUserData);
          }
        })
        .catch(() => {
          Toast("上传失败!");
        });
    },
    // 人脸绑定
    handler() {
      if (this.fileList.length !== 1) {
        this.$refs.uploader.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handler());
      }
    },

    // 人脸绑定（拍照）
    handlerImage() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderImage.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerImage());
      }
    },

    // 人脸绑定（相册）
    handlerAlbum() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderAlbum.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerAlbum());
      }
    },
    getInfoFn(id) {
      getTransferListAPI().then((res) => {
        if (res.code === 0) {
          res.data.map((item) => {
            if (item.id === id) {
              this.addUserData = item;
            }
          });
        }
      });
    },
    toBack() {
      this.$router.back();
    },
    toAddUserInfoFn() {
      this.$router.push({
        name: "PickAddUserInfo",
        query: { item: JSON.stringify(this.addUserData) },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.inform {
  .inform_top {
    height: 48px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #ddd;
    .left {
      position: absolute;
      left: 1rem;
      img {
        // width: 35px;
        height: 30px;
      }
    }
  }
  .face {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .tips {
    .title {
      font-size: 1.2rem;
      font-weight: 900;
      color: rgba(179, 180, 179);
      margin: 40px 20px 10px 20px;
    }
    ul {
      font-size: 0.8rem;
      color: #444;
      li {
        line-height: 20px;
        padding: 0 15px;
      }
    }
  }
  .upload {
    width: 100%;
    position: fixed;
    bottom: 0;
    flex: 1;
    box-sizing: border-box;
    background: #f3f3f3;
    .item-flex {
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      height: 4rem;
      line-height: 4rem;
      text-align: center;
      color: rgba(57, 70, 77);
    }
    .item-flex:first-child {
      border-bottom: 1px solid #eee;
    }
    & > .faceExplain {
      padding: 1rem;
      h2 {
        color: mediumturquoise;
        font-weight: 700;
        font-size: 1.3rem;
      }
      & > ul {
        li {
          font-size: 1rem;
          margin-bottom: 1.3rem;
          color: black;
        }
      }
    }
  }
}
</style>